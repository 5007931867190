import React, { useState, useMemo, useRef, useEffect } from "react";
import TinderCard from "react-tinder-card";
import Header from "../Header";
import Footer from "../Footer";
import InfoModal from "../modals/InfoModal/InfoModal";
import MatchPageModal from "../modals/MatchPageModal/MatchPageModal";
import TutorialInfo from "../modals/TutorialInfo/TutorialInfo";
import FilterModal from "../modals/FilterModal/FilterModal";
import shuffledActivities from "../../data/mappedActivities";
import CallToActionModal from "../modals/CallToActionModal/CallToActionModal";
import "./HomeScreen.css";
import uuid from "react-uuid";
import { FavouriteAddedNotification } from "../notifications/FavouriteAddedNotification/FavouriteAddedNotification";
import Modal from "react-modal";
import FavouritesModal from "../modals/FavouritesModal/FavouritesModal";

const initialActivities = shuffledActivities.slice(0, 10);
const initialRemainingActivities = shuffledActivities.slice(10, shuffledActivities.length);

const HomeScreen = () => {
  Modal.setAppElement(document.getElementById('root'));

  const [activities, setActivities] = useState(initialActivities);
  const [remainingActivities, setRemainingActivities] = useState(initialRemainingActivities)
  const [filterTags, setFilterTags] = useState([]);
  const [displayFilterModal, setDisplayFilterModal] = useState(false);
  const [displayFavouritesModal, setDisplayFavouritesModal] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayMatchPageModal, setDisplayMatchPageModal] = useState(false);
  const [displayCallToActionModal, setDisplayCallToActionModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(activities.length - 1);
  const [swipedActivity, setSwipedActivity] = useState(activities[activities.length - 1]);
  const [canGoBack, setCanGoBack] = useState(false);
  const [toolTipModal, setToolTipModal] = useState(false);
  const [showInfoButtons, setShowInfoButtons] = useState(true)
  const [savedActivities, setSavedActivities] = useState(
    JSON.parse(localStorage.getItem("savedActivities"))
  );
  const [showFavouritesAddedNotification, setShowFavouritesAddedNotification] =
    useState(false);
  const [totalSwipedActivities, setTotalSwipedActivities] = useState(0);
  const [infoActivity, setInfoActivity] = useState(activities[activities.length - 1])

  useEffect(() => {
    localStorage.setItem("savedActivities", JSON.stringify(savedActivities));
  }, [savedActivities]);

  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(activities.length)
        .fill(0)
        .map((i) => React.createRef()),
    [activities]
  );

  useEffect(() => {
    setCanGoBack(totalSwipedActivities > 0);
  }, [totalSwipedActivities]);

  const updateCurrentIndex = (val) => {
    if (val === -1) {
      val = activities.length - 1;
    }
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const updateActivities = () => {
    let reorderedActivities = []
    if (remainingActivities.length !== 0){
      reorderedActivities = [remainingActivities[0], ...activities.slice(0, -1)];
      const currentActivity = activities[activities.length - 1];
      setRemainingActivities([...remainingActivities.slice(1, remainingActivities.length), currentActivity])
    }else{
      reorderedActivities = [activities[activities.length-1], ...activities.slice(0, -1)];

    }
    reorderedActivities.forEach((element) => {
      element.key = uuid();
    });
    setActivities(reorderedActivities);
  };

  // set last direction and decrease current index
  const swiped = (dir) => {
    setSwipedActivity(activities[activities.length - 1]);
    setInfoActivity(activities[activities.length - 2])
    setDisplayInfoModal(false);
    updateActivities();
    updateCurrentIndex(currentIndex - 1);
    if (dir === "up") onSwipeUp();
    else {
      if (dir === "right") {
        setDisplayMatchPageModal(true);
        save();
      }
      setTotalSwipedActivities(totalSwipedActivities + 1);
    }
  };

  const onSwipeUp = (dir) => {
    if (dir === "up") {
      setShowInfoButtons(true);
      setDisplayInfoModal(true);
      setDisplayMatchPageModal(false);
    }
  };


  const outOfFrame = (idx) => {
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current?.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
  };

  // increase current index and show card
  const goBack = async () => {
    if (canGoBack) {
      setTotalSwipedActivities(totalSwipedActivities - 1);

      const currentActivity = remainingActivities[remainingActivities.length-1]
      const reorderedActivities = [
        ...activities.slice(1, activities.length),
        currentActivity,
      ];
      reorderedActivities.forEach((element) => {
        element.key = uuid();
      });
      setActivities(reorderedActivities);
      setRemainingActivities([activities[0], ...remainingActivities.slice(0, -1)])

      let newIndex;
      if (currentIndex === activities.length - 1) {
        setCurrentIndex(0);
        newIndex = 0;
      } else {
        newIndex = currentIndex + 1;
      }
      updateCurrentIndex(newIndex);
      await childRefs[newIndex].current.restoreCard();
    }
  };

  const matchPageBack = () => {
    setDisplayMatchPageModal(true);
    setDisplayInfoModal(false);
  };

  const save = () => {
    const current_activity = activities[activities.length - 1];
    if (savedActivities === null) {
      setSavedActivities([current_activity]);
    } else if (!isSaved(current_activity)) {
      setSavedActivities([...savedActivities, current_activity]);
    }
    displayFavouriteAddedNotification();
  };

  const isSaved = (current_activity) => {
    const activityNames = savedActivities.map((activity) => {
      return activity.name;
    });
    return activityNames.includes(current_activity.name);
  };

  const displayFavouriteAddedNotification = () => {
    setShowFavouritesAddedNotification(true);
    setTimeout(() => {
      setShowFavouritesAddedNotification(false);
    }, 1200);
  };

  const startActivity = () => {
    setDisplayCallToActionModal(true)
    setDisplayMatchPageModal(false)
  }

  const resetInfoActivity = () => {
    setInfoActivity(activities[activities.length - 1])
  }

  return (
    <>
      <Header
        displayFilterModal={displayFilterModal}
        displayFavouritesModal={displayFavouritesModal}
        setDisplayFilterModal={setDisplayFilterModal}
        setDisplayFavouritesModal={setDisplayFavouritesModal}
        setToolTipModal={setToolTipModal}
        resetInfoActivity={resetInfoActivity}
      />
      <div className="cardContainer">
        {activities.length === 0 && (
          <div className="no-activity">
            <h2>
              Oops! Seems like there is no activity that matches your filters.
            </h2>
          </div>
        )}
        {activities.map((activity, index) => (
          <TinderCard
            ref={childRefs[index]}
            className="tinderCard"
            key={activity.key}
            onSwipe={(dir) => swiped(dir)}
            onCardLeftScreen={() => outOfFrame(index)}
            preventSwipe={["up", "down"]}
            onSwipeRequirementFulfilled={(dir) => onSwipeUp(dir)}
          >
            <div
              style={{ backgroundImage: "url(" + activity.imgUrl + ")" }}
              className="card"
            >
              <h2>{activity.name}</h2>
            </div>
          </TinderCard>
        ))}
        {showFavouritesAddedNotification && (
        <FavouriteAddedNotification />
        )}
      </div>
      {(!displayFilterModal) && activities.length !== 0 && (
        <Footer
          canGoBack={canGoBack}
          goBack={goBack}
          swipe={swipe}
          save={save}
          onSwipeUp={onSwipeUp}
        />
      )}
      {displayInfoModal && (
        <div className="moreInfoModal">
          <InfoModal
            onClose={() => {
              setDisplayInfoModal(false);
            }}
            onBack={matchPageBack}
            swipe={swipe}
            setDisplayInfoModal={setDisplayInfoModal}
            save={save}
            activity={infoActivity}
            showFavouritesAddedNotification={showFavouritesAddedNotification}
            showInfoButtons={showInfoButtons}
          />
        </div>
      )}
      {displayMatchPageModal && (
        <div className="MatchPageModal">
          <MatchPageModal
            activity={swipedActivity}
            onClose={() => {
              setDisplayMatchPageModal(false);
            }}
            startActivity={startActivity}
          />
        </div>
      )}
      {displayCallToActionModal && (
        <CallToActionModal
          setDisplayCallToActionModal={setDisplayCallToActionModal}
          activity={swipedActivity}
        />
      )}
      {displayFilterModal && (
        <div className="filterModal">
          <FilterModal
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            shuffledActivities={shuffledActivities}
            setActivities={setActivities}
            setRemainingActivities={setRemainingActivities}
          />
        </div>
      )}
      {displayFavouritesModal && (
        <div className="filterModal">
          <FavouritesModal
            savedActivities={savedActivities}
            setSavedActivities={setSavedActivities}
            setDisplayInfoModal={setDisplayInfoModal}
            setInfoActivity={setInfoActivity}
            setDisplayCallToActionModal={setDisplayCallToActionModal}
            setSwipedActivity={setSwipedActivity}
          />
        </div>
      )}
      <Modal
        className="modal"
        isOpen={toolTipModal}
        onRequestClose={() => {
          setToolTipModal(false);
        }}
        aria-labelledby="modal-label"
      >
        <TutorialInfo/>
      </Modal>
    </>
  );
};

export default HomeScreen;