import "./FavouritesModal.css";
import React from "react";
import { FaTrash, FaInfoCircle, FaPlay } from 'react-icons/fa';

const FavouritesModal = ({savedActivities, setSavedActivities, setDisplayInfoModal, setInfoActivity, setDisplayCallToActionModal, setSwipedActivity}) => {
    const openInfoModal = savedActivity => {
        setInfoActivity(savedActivity)
        setDisplayInfoModal(true)
    }
    const openActionModal = savedActivity => {
        setSwipedActivity(savedActivity)
        setDisplayCallToActionModal(true)
    }
    return (
    <>
    {(savedActivities == null || savedActivities.length === 0) &&
    <div className="nothing-saved">
        <div className="no-fav-title">No Favourites yet!</div>
        <div className="no-fav-text">Once you favourite an activity you will see it here</div>
    </div>
    }
    <div className="fav-card-container">
        {savedActivities != null && savedActivities.map((savedActivity) => (
        <div key={savedActivity.key} className="fav-card">
            <div className="fav-image">
                <img src={savedActivity.imgUrl} alt="activity" />
            </div>
            <div className="fav-content">{savedActivity.name} 
            </div>
            <div className="action-icons"> 
                <FaInfoCircle 
                color='#c974ffcc'
                className={'fav-icon'} 
                onClick={ () => openInfoModal(savedActivity) } 
                />
                <FaPlay
                color='#9dfaa8cc' 
                className={'fav-icon'} 
                onClick={ () => openActionModal(savedActivity) }
                />
                <FaTrash 
                color='#FF6E43' 
                className='fav-icon' 
                onClick={() => {
                    setSavedActivities(
                    savedActivities.filter(deleteActivity =>
                        deleteActivity.name !== savedActivity.name)
                        );
                    }}/>
            </div>
        </div>
        ))}
    </div>
    </>
    );

};

export default FavouritesModal;
