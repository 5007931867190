import React from 'react'
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button"
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import "./CallToActionModal.css"

const CallToActionModal = ({ setDisplayCallToActionModal, activity }) => {
  return (
    <div className='callToActionModal'>
      <div className='callToActionContainer'>
        <div
          className="imageBanner"
          style={{backgroundImage: "url(" + activity.imgUrl + ")"}}
        >
          <div className='callToActionModalHeader'>
          <FaArrowAltCircleLeft className='closeButton' color='white'
            size={25}
            onClick={( )=> setDisplayCallToActionModal(false)}
          />
          <h3 className={'activityName'}>
            {activity.name}
          </h3>
          </div>
        </div>
        <div className='actionContainer'>
          <h3>About/Information</h3>
          <p className='description'>In this activity, you will get a chance to take up a new hobby.</p>
        </div>
        {activity.callToAction.video && 
          <div className='actionContainer'>
          <h3>Action Video</h3>
          <iframe title={activity.name} src={`https://www.youtube.com/embed/${activity.callToAction.video.split("=")[1]}`}> </iframe>
          <p className='description'>{activity.callToAction.description.replace("[name of Activity!]", activity.name)}</p>
        </div>
        }
        {(activity.callToAction.app.includes("apple")) &&
        <div className='actionContainer'>
          <h3>App Link</h3>
          <div className='appButtons'>
            <AppStoreButton
              url={activity.callToAction.app}
              theme={"light"}
              height={50}
              className={"iosButton"}
            />
          </div>
          <p className='description'>
            Download this app to get started on your chosen activity.
          </p>
        </div>
        } 
        {(activity.callToAction.app.includes("google")) &&
        <div className='actionContainer'>
          <h3>App Link</h3>
          <div className='appButtons'>
            <GooglePlayButton
              url={activity.callToAction.app}
              theme={"light"}
              height={50}
              className={"androidButton"}
            />
          </div>
          <p className='description'>
            Download this app to get started on your chosen activity.
          </p>
        </div>
        }
        
        {activity.callToAction.website &&
        <div className='actionContainer'>
          <h3>Website Link</h3>
          <a href={activity.callToAction.website}>{activity.callToAction.website}</a>
        </div>
        }
      </div>
    </div>
  )
}

export default CallToActionModal