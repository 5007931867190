import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ id, label, filterTags, setFilterTags }) => {

  const handleFilterToggle = () => {
    const tempTags = [...filterTags];
    const index = filterTags.indexOf(id);

    if (index > -1) {
      tempTags.splice(index, 1);
      setFilterTags([...tempTags]);
    } else {
      tempTags.push(id);
      setFilterTags([...tempTags]);
    }
  }


  return (
    <div className="container">
      {label}
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox"
          name={id} id={id} checked={filterTags.includes(id)} onChange={handleFilterToggle} />
        <label className="label" htmlFor={id}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;