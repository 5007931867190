import "./InfoModal.css";
import React from 'react'
import {FaCheck, FaStar, FaTimes, FaArrowCircleUp} from 'react-icons/fa';
import {FavouriteAddedNotification} from "../../notifications/FavouriteAddedNotification/FavouriteAddedNotification";

const InfoModal = ({ swipe, setDisplayInfoModal, save, activity, showFavouritesAddedNotification, showInfoButtons }) => {

    return (
        <div className="infoModal">
            <div className="infoCardWidth">
                <div className="infoCardContainer">
                    <div
                        className="infoImageBanner"
                        style={{backgroundImage: "url(" + activity.imgUrl + ")"}}
                    >
                        <div className={'info-modal-header'}>
                            <FaArrowCircleUp className='closeInfoButton'  
                                size={25}
                                onClick={() => setDisplayInfoModal(false)}
                            />
                            <h3 className={'infoActivityName'}>
                                {activity.name}
                            </h3>              
                        </div>
                    </div>
                    <div className={'info-model-body'}>
                        <h3 className={'about-info-title'}>About/Information</h3>
                        <p className={'info-top-description'}>In this activity, you will get a chance to take up a new hobby.</p>
                        {activity.info.video && <>
                            <h3>Information Video</h3>
                            <iframe title={activity.name} src={`https://www.youtube.com/embed/${activity.info.video.split("=")[1]}`}> </iframe>
                        </>
                        }

                        <p>{activity.info.description.replace("[Activity Name]", activity.name)}
                        </p>
                    </div>
                </div>
                <div className="fav-added-container" >
                    {showFavouritesAddedNotification && <FavouriteAddedNotification />}
                </div>
                                 
                <div className="infoModalButtonContainer">
                {showInfoButtons && 
                <div className="icon">
                        <FaTimes
                            className="circle"
                            color='#FF6E43CC'
                            size={45}
                            onClick={() => swipe('left')}
                        />
                    </div> }
                {showInfoButtons && <div className="icon">
                        <FaStar
                            className="circle"
                            onClick={save}
                            color='#4ECFFFCC'
                            size={45}
                            />
                    </div>}
                    <div className="icon">
                        <FaCheck
                            className="circle"
                            onClick={() => swipe('right')}
                            color='#9DFAA8CC'
                            size={45}
                            />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default InfoModal;