import React from 'react'
import "./HeaderAndFooter.css";
import {FaStar, FaFilter, FaBuffer} from 'react-icons/fa';
import {FiHelpCircle} from 'react-icons/fi';

const Header = ({displayFilterModal, displayFavouritesModal, setDisplayFilterModal, setDisplayFavouritesModal, setToolTipModal, resetInfoActivity}) => {

    const handleHomeIconClick = () => {
        setDisplayFilterModal(false);
        setDisplayFavouritesModal(false);
        resetInfoActivity()
        }

    const handleFilterIconClick = () => {
        setDisplayFilterModal(true);
        setDisplayFavouritesModal(false);
        }

    const handleFavouritesIconClick = () => {
        setDisplayFilterModal(false);
        setDisplayFavouritesModal(true);
        }

    return (
    <div className='iconContainer headerIcons'>
        <FaFilter className = {['icon', displayFilterModal ? 'active' : ''].join(' ')} onClick={handleFilterIconClick}/>
        <FaBuffer className = {['icon', !displayFilterModal && !displayFavouritesModal ? 'active' : ''].join(' ')} onClick={handleHomeIconClick}/>
        <FaStar className = {['icon', displayFavouritesModal ? 'active' : ''].join(' ')} onClick={handleFavouritesIconClick}/>
        <FiHelpCircle className='icon' onClick={()=>setToolTipModal(true)}/>
    </div>
    )
}

export default Header