import './FavouriteAddedNotification.css';
import { FaCheck } from 'react-icons/fa';
export const FavouriteAddedNotification = () => (
    <div className={'favourite-added-notification'}>
        <div className={'green-tick-container'}>
            <div className={'tick-icon-container'}>
                <FaCheck size={45} />
            </div>
        </div>
        <div className={'favourite-added-message-container'}>
            <span>Added to Favourites</span>
        </div>
    </div>
);