import uuid from "react-uuid";

const filterTagGroups = [
  [
    { id: "indoor", label: "Indoor", key: uuid() },
    { id: "outdoor", label: "Outdoor", key: uuid() },
  ],
  [
    { id: "group", label: "Group Activity", key: uuid() },
    { id: "individual", label: "Individual Activity", key: uuid() },
  ],
  [
    { id: "quick", label: "Quick", key: uuid() },
    { id: "long-term", label: "Long-term", key: uuid() },
  ],
];

export default filterTagGroups;
