import "./MatchPageModal.css";
import Lottie from "lottie-react";
import confettiAnimation from "../../../img/confetti.json";
import { FaPlay, FaPersonRunning } from "react-icons/fa6";
import "@fontsource/lobster";

const MatchPageModal = ({ activity, onClose, startActivity }) => {
  return (
    <div
      className="matchPageImage"
      style={{ backgroundImage: "url(" + activity.imgUrl + ")" }}
    >
      <Lottie animationData={confettiAnimation} loop={false} />
      <div className="chosenHeader">
        <h2 className="chosenText">You've chosen {activity.name}!</h2>
      </div>
      <div className="buttonsContainer">
        <div className="button" onClick={() => startActivity()}>
          <FaPersonRunning className="matchIcon"></FaPersonRunning>
          <p className="matchIconText">Start Activity</p>
        </div>
        <div className="button" onClick={() => onClose()}>
          <FaPlay className="matchIcon"></FaPlay>
          <p className="matchIconText">Keep Playing</p>
        </div>
      </div>
    </div>
  );
};

export default MatchPageModal;
