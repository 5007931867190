import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomeScreen from "./components/views/HomeScreen";
import ExcelToJson from "./components/views/ExcelToJson";

const App = () => {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<HomeScreen />}/>
        <Route path="/export" element={<ExcelToJson/>}/>
        <Route path="*" element={<HomeScreen />}/>
      </Routes>
    </div>
  );
};

export default App;
