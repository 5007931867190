import {FaStar, FaFilter, FaBuffer} from 'react-icons/fa';
import {FiHelpCircle} from 'react-icons/fi';
import "./TutorialInfo.css";

const TutorialInfo = () => {
  return (
    <div className='tutorialInfoContainer'>
      <h1>Welcome to Miricards!</h1>
      <h4>
        The fun way to discover a new hobby or activity to improve your mental
        health
      </h4>
      <p>
        You'll be presented different acitivities in the form of <b>cards</b>{" "}
        these cards can be swiped <i>left</i>, <i>right</i>,and <i>up</i>.
      </p>
      <ul>
        <li>
          <i>Left</i> moves to the next activity
        </li>
        <li>
          <i>Right</i> selects the activity and gives details on how to
          accomplish it
        </li>
        <li>
          <i>Up</i> gives more information about the acitivty
        </li>
      </ul>
      <p>
        All these actions can be carried out using the buttons on the footer. The header contains a list of icons that are explained below.
      </p>
      <ul>
        <li><FaFilter/> - Filter page, to change which acitiites are visible</li>
        <li><FaBuffer/> - Home page, to swip through activites</li>
        <li><FaStar/> - Favourites page, here is where your list of FavouritesModal will be shown</li>
        <li><FiHelpCircle/> - Help modal, redisplay this tutorial modal</li>
      </ul>
    </div>
  );
};

export default TutorialInfo;
