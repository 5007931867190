import React from "react";
import "./HeaderAndFooter.css";
import { FaCheck, FaStar, FaTimes, FaInfo } from "react-icons/fa";
import { GrUndo } from "react-icons/gr";

const Footer = ({ canGoBack, goBack, swipe, save, onSwipeUp }) => {
  return (
    <div id='footContainer' className='iconContainer'>
      <div className='icon'>
        <GrUndo
          style={{ color: canGoBack ? "#FCD432CC": "gray" }}
          size={30}
          onClick={goBack}
          className="circle"
        />
        </div>
        <div className='icon'>
          <FaTimes 
            color='#FF6E43CC'
            size={45}
            onClick={() => swipe('left')}
            className="circle"
          />
        </div>
        <div className='icon'>
          <FaStar
            onClick={save}
            color='#4ECFFFCC'
            size={30}
            className="circle"
          />
          </div>
        <div className='icon'>
          <FaCheck
            onClick={() => {swipe('right')}}
            color='#9DFAA8CC'
            size={45}
            className="circle"
          />  
        </div>
        <div className='icon'>
          <FaInfo
            onClick={() => onSwipeUp('up')}
            color='#C974FFCC'
            size={30}
            className="circle"
          />
        </div>
    </div>
  );
};

export default Footer;
