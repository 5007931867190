import uuid from "react-uuid";

var mappedActivities = [];
let rawActivities = require("./rawActivities.json");

function shuffle(array) {
  let i = array.length;
  while (i--) {
    const ri = Math.floor(Math.random() * i);
    [array[i], array[ri]] = [array[ri], array[i]];
  }
  return array;
}

for (var i = 0; i < rawActivities.length; i++) {
  mappedActivities.push({
    name: rawActivities[i].name,
    imgUrl: require("../img/" + rawActivities[i].imgName + ".png"),
    key: uuid(),
    info: rawActivities[i].info,
    tags: rawActivities[i].tags.map(tag => tag.toLowerCase()),
    callToAction: rawActivities[i].callToAction
  })
}
var shuffledActivities = shuffle(mappedActivities)

export default shuffledActivities;
