import React, {useState} from "react";
import * as XLSX from "xlsx";

const ExcelToJson = () => {
    const [jsonData, setJsonData] = useState([]);
    const saveTemplateAsFile = (filename, dataObjToWrite) => {
        const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
        const link = document.createElement("a");

        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        link.dispatchEvent(evt);
        link.remove()
    };
    const formatJson = (data) => {
        let formatted = []
        for (let elmnt in data) {
            let tags = data[elmnt]["Tags"]
            let tagsArray = typeof(tags) != "undefined" ? tags.split(",") : []
            let formattedObj = {
                name: data[elmnt]["Activity"],
                imgName: data[elmnt]["Image name"],
                tags: tagsArray,
                info: {
                    description: data[elmnt]["Information Screen Description"],
                    video: "Information Screen Video" in data[elmnt] ? data[elmnt]["Information Screen Video"] : "",
                },
                callToAction: {
                    description: data[elmnt]["Call To Action"],
                    video: "Call To Action Video" in data[elmnt] ? data[elmnt]["Call To Action Video"] : "",
                    app: "App" in data[elmnt] ? data[elmnt]["App"] : "",
                    website: "Website" in data[elmnt] ? data[elmnt]["Website"] : ""
                }
            }
            formatted.push(formattedObj)
        }
        saveTemplateAsFile('rawActivities.json', formatted);
        return formatted
    }

    const addfile = (event) => {
        let file = event.target.files[0];
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          let arrayBuffer = fileReader.result;
          let data = new Uint8Array(arrayBuffer);
          let arr = [];
          for (let i = 0; i !== data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");
          let workbook = XLSX.read(bstr, { type: "binary" });
          let first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];
          let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          console.log(arraylist)
          setJsonData(formatJson(arraylist));
        };
      }

    return (
    
    <div>
        <input
        type="file"
        placeholder="Upload file"
        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={(event) => {
          addfile(event)
        }}
        />
        <pre onClick={() => {navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2))}}>{JSON.stringify(jsonData, null, 2) }</pre>
    </div>
     
    );

};

export default ExcelToJson;
