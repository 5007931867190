import "./FilterModal.css";
import { useEffect } from "react";
import ToggleSwitch from "../../ToggleSwitch.js";
import filterTagGroups from "../../../data/FilterTags.js";
import uuid from "react-uuid";


const FilterModal = ({filterTags, setFilterTags, shuffledActivities, setActivities, setRemainingActivities}) => {

  useEffect(() => {
    const hasTags = (activity) => {
      return filterTags.reduce((acc, tag) => acc && activity.tags.includes(tag), true);
    }

    const filteredActivities = shuffledActivities.filter(hasTags);
    const initialActivities = filteredActivities.slice(0, 10);
    const initialRemainingActivities = filteredActivities.slice(
      10,
      filteredActivities.length - 1
    );

    setActivities(initialActivities);
    setRemainingActivities(initialRemainingActivities);
    
  }, [filterTags, shuffledActivities, setActivities, setRemainingActivities]);

  return (
    <div className="filterModal">
      <div className="filterCardContainer">
        <h1 className="filterTitle">Filters</h1>
        <div>
          {filterTagGroups.map((filterGroup) => {
            return (
            <ul className="filterGroup" key={uuid()}>
            {filterGroup.map((filter) => {
              return (
                <ToggleSwitch
                  key={filter.key}
                  filterTags={filterTags}
                  setFilterTags={setFilterTags}
                  id={filter.id}
                  label={filter.label}
                />
              );
            })};
            </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
